import api from "../queries/api";
import { QueryFunctionContext } from "react-query";
import { TaskModel, TaskReadModel } from "../pages/myTask/SideBarPanel";
import { History } from "../pages/aggrid/Activity";
import captableApi from "../queries/cpatableApi";

export interface ProjectModel {
  name: string;
  projectType: string;
  description: string;
  companyId?: string;
  projectId?: string;
  subTypeId?: string;
  companyName?: string;
  startDate?: string;
  endDate?: string;
  tags?: string[];
}
export interface ReminderModel {
  projectId: string;
  taskId: string;
  notifiers: string[];
}

export interface FilterConditionModel {
  isFilterSave: boolean;
  isColumnSave: boolean;
}

export interface HistoryModel {
  id: string;
  entityType: string;
  action: string;
  oldValue: {
    oldValue: any;
  };
  newValue: {
    newValue: any;
  };
  performedById: string;
  timestamp: string;
  relatedEntity: string;
  relatedEntityId: string;
  taskId: string;
  referenceId: string;
  userId: string;
  userName: string;
}

type ApproveAmpType = {
  state: "CLOSED" | "INPROGRESS";
  comment?: string;
};

export async function getAllProject(): Promise<any> {
  return api.get(`project/all`).then((res) => res.data.data);
}
export async function getOverview(): Promise<any> {
  return api.get(`/overview`).then((res) => res.data.data);
}

export async function getProjectDetails(
  context: QueryFunctionContext
): Promise<any> {
  const projectId = context.queryKey[1];
  if (!projectId) {
    return;
  }
  return api.get(`project`, { params: { projectId } }).then((res) => res.data);
}
export async function getCompanies(): Promise<any> {
  return api.get(`companies`).then((res) => res.data);
}

export async function getNotifications(): Promise<any> {
  return api.get(`notifications`).then((res) => res.data);
}

export async function createProject(
  projectDetails: ProjectModel
): Promise<any> {
  return api.post(`project/create`, projectDetails).then((res) => res.data);
}

export async function updateProject(
  projectDetails: ProjectModel
): Promise<any> {
  return api.put(`project`, projectDetails).then((res) => res.data);
}

export async function deleteProject(projectId: any): Promise<any> {
  return api.delete(`project?projectId=${projectId}`).then((res) => res.data);
}

export async function deleteTask(projectId: any, taskId: any): Promise<any> {
  return api
    .delete(`project/task?projectId=${projectId}&taskId=${taskId}`)
    .then((res) => res.data);
}
export async function sendReminder(reminderBody: ReminderModel): Promise<any> {
  return api
    .post(`project/task/sendDueReminder`, reminderBody)
    .then((res) => res.data);
}

export async function getAllTasks(context: QueryFunctionContext): Promise<any> {
  const projectId = context.queryKey[1];
  if (!projectId) {
    return;
  }
  return api
    .get(`project/task/all`, { params: { projectId } })
    .then((res) => res.data);
}

export async function getProjectExport(
  context: QueryFunctionContext
): Promise<any> {
  const projectId = context.queryKey[1];
  return api
    .get(`project/export`, { params: { projectId } })
    .then((res) => res.data);
}

export async function getAllTaskHistory(
  context: QueryFunctionContext
): Promise<History[]> {
  const taskId = context.queryKey[1];
  return api
    .get(`project/task/history`, { params: { taskId } })
    .then((res) => res.data.data);
}

export async function getTaskDetails(
  context: QueryFunctionContext
): Promise<any> {
  const taskId = context.queryKey[1];
  if (!taskId) {
    return;
  }
  return api
    .get(`project/task`, { params: { taskId } })
    .then((res) => res.data.data);
}

export async function approveAmp(
  approveAmpDetails: ApproveAmpType
): Promise<TaskModel> {
  return api
    .post(
      `amp/approve?status=${approveAmpDetails.state}&comment=${
        approveAmpDetails.comment
      }&__amp_source_origin=${""}`
    )
    .then((res) => res.data.data);
}

export async function createTask(taskDetails: any): Promise<any> {
  return api.post(`project/task/create`, taskDetails).then((res) => res.data);
}

export async function updateTask(taskDetails: TaskModel): Promise<any> {
  return api.put(`project/task`, taskDetails).then((res) => res.data);
}

export async function updateTaskRead(taskDetails: TaskReadModel): Promise<any> {
  return api.post(`project/task/read`, taskDetails).then((res) => res.data);
}

export interface TableStateModel {
  filterState: any;
  columnState: any;
  isFilterSave: boolean;
  isColumnSave: boolean;
}

export async function getTableFilterState(
  context: QueryFunctionContext
): Promise<any> {
  const tableId = context.queryKey[1];
  return captableApi
    .get(`utils/tableState?id=${tableId}&platform=TASKMANAGER`)
    .then((res) => res.data.data);
}

export async function postTableFilterState({
  tableId,
  companyId,
  filterData,
}: {
  tableId: string;
  companyId: string;
  filterData: TableStateModel;
}): Promise<any> {
  const postData = {
    id: tableId,
    data: filterData,
    companyId,
    platform: "TASKMANAGER",
  };
  return captableApi.put(`utils/tableState`, postData).then((res) => res.data);
}

export async function deleteTableState(tableId: string): Promise<any> {
  return captableApi
    .delete(`utils/tableState?id=${tableId}&platform=TASKMANAGER`)
    .then((res) => res.data);
}

export async function getProjectTypes(): Promise<any> {
  return api.get(`/templates`).then((res) => res.data.data);
}

export async function getSubTemplates(): Promise<any> {
  return api.get(`project/subTemplate`).then((res) => res.data.data);
}

export async function deleteSubTemplate({
  templateID,
}: {
  templateID: any;
}): Promise<any> {
  return api
    .delete(`project/subTemplate`,{
      params:{
        templateId: templateID
      }
    }
    )
    .then((res) => res.data);
}
