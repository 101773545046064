import React, { useState, useEffect } from "react";
import { Mail, FileDown, Check, MailCheck } from "lucide-react";
import {
  useDownloadProjectDocument,
  useDownloadTaskDocument,
} from "../../queries/document";

const CustomDownloadDialog = ({
  isProject,
  projectId,
  selected = [],
  isOpen,
  isTag,
  onOpenChange,
}: {
  projectId: string;
  isProject: boolean;
  selected: any[];
  isOpen: boolean;
  isTag?: boolean;
  onOpenChange: (openChange: boolean) => void;
}) => {
  const [showDialog, setShowDialog] = useState(isOpen);
  const [animationStage, setAnimationStage] = useState(0);

  const { mutate: downloadProjectDocument } = useDownloadProjectDocument();
  const { mutate: downloadTaskDocument } = useDownloadTaskDocument();

  function downloadFile() {
    if (isProject) {
      downloadProjectDocument({
        projectId: projectId,
        keys: selected.map((ele) => ele.location),
        includePastRecurringTasks: false,
      });
    } else {
      downloadTaskDocument({
        projectId: projectId,
        tagTasks: isTag,
        keys: selected.map((ele) => ele.location),
        includePastRecurringTasks: false,  
      });
    }
  }
  useEffect(() => {
    if (isOpen) {
      downloadFile();
      setShowDialog(true);
      setAnimationStage(0);
      const stageTimer = setTimeout(() => setAnimationStage(1), 1000);
      const closeTimer = setTimeout(() => {
        setAnimationStage(2);
        setTimeout(() => {
          setShowDialog(false);
          onOpenChange(false);
        }, 3000);
      }, 3000);

      return () => {
        clearTimeout(stageTimer);
        clearTimeout(closeTimer);
      };
    }
  }, [isOpen, onOpenChange]);

  if (!showDialog) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div
        className={`bg-white rounded-lg p-6 max-w-sm w-full 
          
        `}
      >
        <div className="flex flex-col items-center">
          <div className="w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center mb-4">
            {animationStage < 2 ? (
              <FileDown
                className={`text-blue-500 transform animate-bounce transition-all duration-300 ${
                  animationStage === 1 ? "translate-y-1" : ""
                }`}
                size={32}
              />
            ) : (
              <MailCheck className="text-green-500" size={32} />
            )}
          </div>
          <h2 className="text-xl font-bold mb-2 text-gray-800">
            Download Started
          </h2>
          <p className="text-gray-600 text-center mb-4">
            {animationStage < 2
              ? "We're preparing your file..."
              : "The download link will be sent to your email and will also be available in the notification section."}
          </p>
          <div
            className={`flex items-center space-x-2 ${
              animationStage === 1 ? "opacity-100" : "opacity-0"
            } transition-opacity duration-300`}
          >
            <div
              className="w-2 h-2 bg-blue-500 rounded-full animate-bounce"
              style={{ animationDelay: "0ms" }}
            ></div>
            <div
              className="w-2 h-2 bg-blue-500 rounded-full animate-bounce"
              style={{ animationDelay: "300ms" }}
            ></div>
            <div
              className="w-2 h-2 bg-blue-500 rounded-full animate-bounce"
              style={{ animationDelay: "600ms" }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomDownloadDialog;
