import { useEffect, useState } from "react";
import { useAuthStore } from "../../store/useAuthStore";
import { useGetOverview } from "../../queries/admin";
import useExtractTasks from "./ExtractTaskHook";
import MyTaskEmptyScreen from "./MyTaskEmptyScreen";
import AGGridSimpleTable from "../aggrid/AGGridSimpleTable";
import { TableId } from "../project/TableIdConstants";
import useIsMobile from "../../utils/detectDevice";
import { decodeAuthToken } from "../../utils/auth";
import { useParams } from "react-router-dom";

const MyTaskPage = () => {
  const { id, taskId, token, companyId, type } = useParams();

  const { setAuth } = useAuthStore();
  if (
    (!useAuthStore ||
      (useAuthStore.getState() && !useAuthStore.getState().accessToken)) &&
    token
  ) {
    const accessToken = token;
    sessionStorage.setItem("accesstoken", accessToken);
  }
  const { data: userData } = useGetOverview();
  let allTask = useExtractTasks(userData);
  if (companyId && companyId !== undefined && companyId !== "0") {
    allTask = allTask.filter((task) => task.companyId === companyId);
  }
  if (type) {
    allTask = allTask.filter((task) => task.projectType === type);
  }
  const { isMobile } = useIsMobile();
  const platForm = isMobile ? "mobile" : "web";

  return (
    <div className="-mx-2">
      <AGGridSimpleTable
        tasks={allTask}
        tableId={TableId.myTaskTable + platForm}
        type={type}
      />
    </div>
  );
};

export default MyTaskPage;
