import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import _ from "lodash";
import { HStack } from "../../shared/utils";
import { Input, TextArea } from "../../shared/InputField";
import { useCreateTask, useGetAllProject } from "../../queries/admin";
import { CubeIcon } from "@heroicons/react/24/outline";
import { Select } from "../../shared/Select";
import SearchAutoCompleteDropDown, {
  SwitchDropDownModel,
} from "./SearchInputBox";
import { useGetUserProjectRoles } from "../../queries/userRole";
import { UserProjectRoles } from "../../api/userRole";
import { X } from "lucide-react";
import { formatDate } from "../../utils/date";
import { ButtonSize, PrimaryCTAButton } from "../../shared/CTAButtonComponents";
import { useFormik } from "formik";
import * as Yup from "yup";
import { queryClient } from "../../queries/client";
import { handleClickForTracking } from "../../analytics";
import { AnalyticsConstant } from "../../AnalyticsContant";

interface DuplicateTaskModel {
  companyName: string;
  groupName: string;
  isRecurring: boolean;
  isGroupTask: boolean;
  taskType: "" | "INDIVIDUAL";
  creatorId: string;
  description: string;
  endDate: string;
  id: string;
  name: string;
  priority: string;
  projectId: string;
  projectName: string;
  projectSpecificStatusId?: string;
  startDate: string;
  status: string;
  tags: string[];
  recurringConfig: RecurringConfig[];
  userTaskRoles: UserDuplicateTaskRoleModel[];
}

export interface RecurringConfig {
  recurrenceInterval: number;
  startDateOffset: number;
  dueDate: number;
  recurrenceEndDate: string;
  createImmediately: boolean;
}

interface UserDuplicateTaskRoleModel {
  email: string;
  id: string;
  name: string;
  role: string;
  taskId: string;
  userId: string;
}

const SideBarAddTaskPanel = ({
  projectIdData,
  taskData,
  isOpen,
  onClose,
  onCreateProject,
}: {
  projectIdData?: any;
  taskData?: DuplicateTaskModel;
  isOpen: any;
  onClose: any;
  onCreateProject: any;
}) => {
  const { data: projectsData } = useGetAllProject();

  const [projectId, setProjectId] = useState(projectIdData ?? "");
  const [manager, setManager] = useState("");
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      tags: taskData?.tags && taskData?.tags?.length > 0 && taskData?.tags?.join(",") ||'',
      taskName: taskData?.name ?? "",
      groupName: taskData?.groupName ?? "",
      projectId: projectIdData ?? "",
      manager: "",
      isGroupTask: taskData?.groupName ?true :false,
      isRecurring: taskData?.isRecurring ?? false,
      priority: taskData?.priority ?? "NORMAL",
      startDate: taskData?.startDate ? formatDate(taskData?.startDate) : "",
      endDate: taskData?.endDate ? formatDate(taskData?.endDate) : "",
      taskDescription: taskData?.description ?? "",
      intervalMonth: 3,
      recurringEndDate: 30,
      recurringLastDate: "",
      recurringStartDate: 20,
    },
    validationSchema: Yup.object().shape({
      taskName: Yup.string().required("Task name is required"),
      taskDescription: Yup.string().required("Task description is required"),
      priority: Yup.string().required("Priority is required"),
      startDate: Yup.date().when("isRecurring", {
        is: false,
        then: Yup.date().required("Start Date is required"),
      }),
      endDate: Yup.date()
            .min(Yup.ref('startDate'), "End date must be after start date"),
      projectId: Yup.string().required("Project name is required"),
      groupName: Yup.string().when("isGroupTask", {
        is: true,
        then: Yup.string().required("Group name is required"),
      }),
      intervalMonth: Yup.number().when("isRecurring", {
        is: true,
        then: Yup.number().required("Interval month is required").min(1),
      }),
      recurringEndDate: Yup.number().when("isRecurring", {
        is: true,
        then: Yup.number()
          .required("Recurring due date is required")
          .min(1)
          .max(30),
      }),
      recurringStartDate: Yup.number().when("isRecurring", {
        is: true,
        then: Yup.number()
          .required("Recurring start date is required")
          .min(1)
          .max(30),
      }),
      recurringLastDate: Yup.date().when("isRecurring", {
        is: true,
        then: Yup.date().required("Recurring last date is required"),
      }),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      setLoading(true);
      taskData
        ? handleClickForTracking(
            AnalyticsConstant.sidebar_addtask_duplicate_task
          )
        : handleClickForTracking(AnalyticsConstant.sidebar_addtask_create_task);
      const assigneeList = assignee.map((ele: any) => ({
        userId: ele.id,
        role: "ASSIGNEE",
      }));
      assigneeList.push({
        userId: values.manager,
        role: "ASSIGNER",
      });

      createTask(
        {
          name: values.taskName,
          groupName: values.groupName,
          taskType: values.isGroupTask ? "INDIVIDUAL" : "SHARED",
          isGroupTask: values.isGroupTask,
          isRecurring: values.isRecurring,
          recurringConfig: [
            {
              recurrenceInterval: values.intervalMonth,
              startDateOffset: values.recurringStartDate,
              dueDate: values.recurringEndDate,
              recurrenceEndDate: values.recurringLastDate
                ? new Date(values.recurringLastDate).toISOString()
                : "",
              createImmediately: false,
            },
          ],
          description: values.taskDescription,
          projectId: values.projectId,
          sendNotification: sendNotification,
          userTaskRoles: assigneeList.filter((ele) => ele.userId),
          priority: values.priority,
          tags: values?.tags && values?.tags?.length > 0 && values?.tags?.split(","),
          startDate: values.startDate
            ? new Date(values.startDate).toISOString()
            : "",
          endDate: values.endDate ? new Date(values.endDate).toISOString() : "",
        },
        {
          onSuccess: (data) => {
            setLoading(false);
            toast("Task Created Successfully", {
              type: "success",
              autoClose: 2000,
            });
            if (projectId) {
              queryClient.invalidateQueries(["getAllTask", projectId]);
              queryClient.refetchQueries(["getAllTask", projectId]);
            }
            queryClient.invalidateQueries("getAllTask");
            queryClient.refetchQueries("getAllTask");

            queryClient.invalidateQueries("overview");

            formik.resetForm();
            onCreateProject();
          },
          onError: (err: any) => {
            setLoading(false);
            toast(err.response.data.message, {
              type: "error",
              autoClose: 2000,
            });
            onClose();
          },
        }
      );
    },
  });

  useEffect(() => {
    refetch();
  }, [formik.values.projectId]);

  useEffect(() => {
    if (taskData) {
      const managerId =
        taskData?.userTaskRoles.find((ele) => ele.role === "ASSIGNER")
          ?.userId ?? "";
      formik.setFieldValue("manager", managerId);
      const assigneeList =
        taskData?.userTaskRoles
          .filter((ele) => ele.role === "ASSIGNEE")
          .map((ele) => ({
            name: ele.name,
            type: ele.email,
            id: ele.userId,
          })) ?? [];
      setAssignee(assigneeList);
    }
  }, [taskData]);

  const { mutate: createTask } = useCreateTask();

  const [newAssignee, setNewAssignee] = useState("");
  const { data: userProjectRoles, refetch } = useGetUserProjectRoles(
    formik.values.projectId
  );
  const [disabled, setDisable] = useState(true);
  const [stopFirstTime, setStopFirstTime] = useState(false);
  const [stopFirstTimeError, setStopFirstTimeError] = useState(false);

  useEffect(() => {
    if (stopFirstTime) {
      if (Object.keys(formik.errors).length === 0) {
        setDisable(true);
      } else {
        setDisable(false);
      }
    } else {
      setStopFirstTime(true);
    }
  }, []);

  useEffect(() => {
    if (stopFirstTimeError) {
      if (Object.keys(formik.errors).length > 0) {
        setDisable(true);
      } else {
        setDisable(false);
      }
    } else {
      setStopFirstTimeError(true);
    }
  }, [formik.errors]);

  useEffect(() => {
    refetch();
  }, [projectId]);
  const [assignee, setAssignee] = useState<SwitchDropDownModel[]>([]);

  useEffect(() => {
    if (taskData) {
      const managerId =
        taskData?.userTaskRoles.find((ele) => ele.role === "ASSIGNER")
          ?.userId ?? "";
      setManager(managerId);
      const assigneeList =
        taskData?.userTaskRoles
          .filter((ele) => ele.role === "ASSIGNEE")
          .map((ele) => ({
            name: ele.name,
            type: ele.email,
            id: ele.userId,
          })) ?? [];
      setAssignee(assigneeList);
    }
  }, [taskData]);
  function createAssignee(value?: SwitchDropDownModel) {
    if (value?.type) {
      setAssignee([...assignee, value]);
    }
  }

  function deleteAssignee(value?: SwitchDropDownModel) {
    if (value?.id) {
      const newList = assignee.filter((ele) => ele.id !== value.id);
      setAssignee(newList);
    }
  }

  useEffect(() => {
    if (manager) {
      const newAssignee = assignee.filter((ele) => ele.id !== manager);
      setAssignee(newAssignee);
    }
  }, [manager]);

  const [sendNotification, setSendNotification] = useState(true);
  const [isGroupTask, setIsGroupTask] = useState(false);
  const [isRecurring, setIsRecurring] = useState(false);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => onClose()}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-128">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => {
                          handleClickForTracking(
                            AnalyticsConstant.sidebar_addtask_close_icon
                          );
                          onClose();
                        }}
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <Icon
                          icon="octicon:x-16"
                          className="text-white h-5 cursor-pointer"
                          height={20}
                        />
                      </button>
                    </div>
                  </Transition.Child>

                  <div className="w-full h-full overflow-y-auto bg-white px-4 pb-4 pt-5 sm:w-full sm:max-w-lg sm:p-6 to-indigo-50 flex flex-col shadow-xl rounded-lg overflow-hidden">
                    <HStack className="items-center justify-between w-full">
                      <HStack className="items-center">
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
                          <CubeIcon
                            className="h-6 w-6 text-indigo-600"
                            aria-hidden="true"
                          />
                        </div>
                        <Dialog.Title
                          as="h3"
                          className="text-base pb-2 pl-2 pt-2 font-semibold leading-6 text-gray-900"
                        >
                          {taskData ? "Duplicate Task" : "Create Task"}
                        </Dialog.Title>
                      </HStack>
                      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse flex flex-row gap-4">
                        <PrimaryCTAButton
                          type="submit"
                          loading={loading}
                          className="sm:h-10 h-8"
                          onClick={(e) => formik.handleSubmit()}
                          buttonSize={ButtonSize.MEDIUM}
                        >
                          {taskData ? "Duplicate Task" : "Create"}
                        </PrimaryCTAButton>
                        <button
                          type="button"
                          className="inline-flex sm:h-10 h-8  w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                          onClick={() => {
                            onClose();
                            handleClickForTracking(
                              AnalyticsConstant.sidebar_addtask_cancel_button
                            );
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </HStack>
                    <div className="sm:flex sm:items-start ">
                      <div className="mt-3 text-center sm:ml-4 w-full sm:mt-0 sm:text-left">
                        <form onSubmit={formik.handleSubmit} className="mt-4">
                          <div className="mb-4">
                            <label
                              htmlFor="taskName"
                              className="block text-sm text-left font-medium text-gray-700"
                            >
                              Task Name
                            </label>
                            <Input
                              type="text"
                              name="taskName"
                              id="taskName"
                              value={formik.values.taskName}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.taskName &&
                              formik.errors.taskName && (
                                <div className="text-red-500 text-sm mt-1">
                                  {formik.errors.taskName}
                                </div>
                              )}
                          </div>
                          <div className="mb-4">
                            <label
                              htmlFor="taskDescription"
                              className="block text-left text-sm font-medium text-gray-700"
                            >
                              Task Description
                            </label>
                            <TextArea
                              id="taskDescription"
                              name="taskDescription"
                              rows={3}
                              value={formik.values.taskDescription}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.taskDescription &&
                              formik.errors.taskDescription && (
                                <div className="text-red-500 text-sm mt-1">
                                  {formik.errors.taskDescription}
                                </div>
                              )}
                          </div>

                          <div className="relative flex items-start pb-3">
                            <div className="flex h-6 items-center">
                              <input
                                id="isGroupTask"
                                name="isGroupTask"
                                type="checkbox"
                                checked={formik.values.isGroupTask}
                                onChange={(e) =>
                                  formik.setFieldValue(
                                    "isGroupTask",
                                    e.target.checked
                                  )
                                }
                              />
                            </div>
                            <div className="ml-3 text-sm leading-6">
                              <label
                                htmlFor="isGroupTask"
                                className="font-medium text-left text-gray-900"
                              >
                                Group Task
                              </label>
                            </div>
                          </div>
                          {formik.values.isGroupTask && (
                            <div className="mb-4">
                              <label
                                htmlFor="groupName"
                                className="block text-sm text-left font-medium text-gray-700"
                              >
                                Group Name
                              </label>
                              <Input
                                type="text"
                                name="groupName"
                                id="groupName"
                                value={formik.values.groupName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                              {formik.touched.groupName &&
                                formik.errors.groupName && (
                                  <div className="text-red-500 text-sm mt-1">
                                    {formik.errors.groupName}
                                  </div>
                                )}
                            </div>
                          )}
                          <div className="relative flex items-start pb-3">
                            <div className="flex h-6 items-center">
                              <input
                                id="isRecurring"
                                name="isRecurring"
                                type="checkbox"
                                checked={formik.values.isRecurring}
                                onChange={(e) =>
                                  formik.setFieldValue(
                                    "isRecurring",
                                    e.target.checked
                                  )
                                }
                              />
                            </div>
                            <div className="ml-3 text-sm leading-6">
                              <label
                                htmlFor="isRecurring"
                                className="font-medium text-left text-gray-900"
                              >
                                Recurring Event
                              </label>
                            </div>
                          </div>
                          {formik.values.isRecurring && (
                            <>
                              <div className="mb-4">
                                <label
                                  htmlFor="intervalMonth"
                                  className="block text-sm text-left font-medium text-gray-700"
                                >
                                  Recurrence Interval
                                </label>
                                <p className="text-xs text-left text-gray-500">
                                  Number of months between task repetitions
                                </p>
                                <Input
                                  type="number"
                                  name="intervalMonth"
                                  id="intervalMonth"
                                  value={formik.values.intervalMonth}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                />
                                {formik.touched.intervalMonth &&
                                  formik.errors.intervalMonth && (
                                    <div className="text-red-500 text-sm mt-1">
                                      {formik.errors.intervalMonth}
                                    </div>
                                  )}
                              </div>
                              <HStack className="w-full gap-4">
                                <div className="mb-4 flex-1">
                                  <label
                                    htmlFor="recurringEndDate"
                                    className="block text-sm text-left font-medium text-gray-700"
                                  >
                                    Due Date
                                  </label>
                                  <p className="text-xs text-left text-gray-500">
                                    Due date for each task instance
                                  </p>
                                  <Input
                                    type="number"
                                    name="recurringEndDate"
                                    id="recurringEndDate"
                                    placeholder="Between 1 to 30"
                                    value={formik.values.recurringEndDate}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                  />
                                  {formik.touched.recurringEndDate &&
                                    formik.errors.recurringEndDate && (
                                      <div className="text-red-500 text-sm mt-1">
                                        {formik.errors.recurringEndDate}
                                      </div>
                                    )}
                                </div>

                                <div className="mb-4 flex-1">
                                  <label
                                    htmlFor="recurringStartDate"
                                    className="block text-sm text-left font-medium text-gray-700"
                                  >
                                    Start Offset
                                  </label>
                                  <p className="text-xs text-left text-gray-500">
                                    {/* No. of days before due date to start the task */}
                                    Days before due date to start task
                                  </p>

                                  <Input
                                    type="number"
                                    name="recurringStartDate"
                                    id="recurringStartDate"
                                    placeholder="Between 1 to 30"
                                    value={formik.values.recurringStartDate}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                  />
                                  {formik.touched.recurringStartDate &&
                                    formik.errors.recurringStartDate && (
                                      <div className="text-red-500 text-sm mt-1">
                                        {formik.errors.recurringStartDate}
                                      </div>
                                    )}
                                </div>
                              </HStack>
                              <div className="mb-4 w-full">
                                <label
                                  htmlFor="recurringLastDate"
                                  className="block text-sm text-left font-medium text-gray-700"
                                >
                                  End Date
                                </label>
                                <p className="text-xs text-left text-gray-500">
                                  Date when the recurring task should stop
                                </p>
                                <Input
                                  type="date"
                                  name="recurringLastDate"
                                  id="recurringLastDate"
                                  placeholder=""
                                  value={formik.values.recurringLastDate}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                />
                                {formik.touched.recurringLastDate &&
                                  formik.errors.recurringLastDate && (
                                    <div className="text-red-500 text-sm mt-1">
                                      {formik.errors.recurringLastDate}
                                    </div>
                                  )}
                              </div>
                            </>
                          )}
                          {!formik.values.isRecurring && (
                            <HStack className="w-full gap-8">
                              <div className="mb-4 w-full">
                                <label
                                  htmlFor="startDate"
                                  className="block text-sm text-left font-medium text-gray-700"
                                >
                                  Start Date
                                </label>
                                <Input
                                  type="date"
                                  name="startDate"
                                  id="startDate"
                                  value={formik.values.startDate}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                />
                                {formik.touched.startDate &&
                                  formik.errors.startDate && (
                                    <div className="text-red-500 text-sm mt-1">
                                      {formik.errors.startDate}
                                    </div>
                                  )}
                              </div>
                              <div className="mb-4 w-full">
                                <label
                                  htmlFor="projectName"
                                  className="block text-sm text-left font-medium text-gray-700"
                                >
                                  End Date
                                </label>
                                <Input
                                  type="date"
                                  name="endDate"
                                  id="endDate"
                                  value={formik.values.endDate}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                />
                                {formik.touched.endDate &&
                                  formik.errors.endDate && (
                                    <div className="text-red-500 text-sm mt-1">
                                      {formik.errors.endDate}
                                    </div>
                                  )}
                              </div>
                            </HStack>
                          )}
                          <HStack className="w-full gap-8">
                            <div className="mb-4 w-full">
                              <label
                                htmlFor="projectName"
                                className="block text-sm text-left font-medium text-gray-700"
                              >
                                Priority
                              </label>

                              <Select
                                placeholder="Select Priority"
                                options={[
                                  "NORMAL",
                                  "LOW",
                                  "MEDIUM",
                                  "HIGH",
                                  "CRITICAL",
                                ]}
                                value={formik.values.priority}
                                onChange={(e) =>
                                  formik.setFieldValue(
                                    "priority",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                            <div className="mb-4 w-full">
                              <div className="mb-4">
                                <label
                                  htmlFor="projectName"
                                  className="block text-sm text-left font-medium text-gray-700"
                                >
                                  Project Name
                                </label>
                                {projectsData && (
                                  <Select
                                    placeholder="Select Project"
                                    options={projectsData.map((ele: any) => ({
                                      id: ele.id,
                                      name: ele.name,
                                    }))}
                                    valueGetter={(o: any) => o.id}
                                    textGetter={(o: any) => o.name}
                                    value={formik.values.projectId}
                                    onChange={(e) =>
                                      formik.setFieldValue(
                                        "projectId",
                                        e.target.value
                                      )
                                    }
                                    onBlur={formik.handleBlur}
                                    name="projectId"
                                  />
                                )}
                                {formik.touched.projectId &&
                                  formik.errors.projectId && (
                                    <div className="text-red-500 text-sm mt-1">
                                      {formik.errors.projectId as any}
                                    </div>
                                  )}
                              </div>
                            </div>
                          </HStack>
                          {projectsData && formik.values.projectId && (
                            <div className="mb-4">
                              <label
                                htmlFor="projectName"
                                className="block text-sm text-left font-medium text-gray-700"
                              >
                                Managed by
                              </label>
                              <Select
                                placeholder="Select Manager"
                                options={(userProjectRoles ?? []).map(
                                  (ele: UserProjectRoles) => ({
                                    userId: ele.userId,
                                    name: ele.name,
                                  })
                                )}
                                valueGetter={(o: any) => o.userId}
                                textGetter={(o: any) => o.name}
                                value={formik.values.manager}
                                onChange={(e) =>
                                  formik.setFieldValue(
                                    "manager",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          )}
                          {projectsData && formik.values.projectId && (
                            <div className="mb-4">
                              <label
                                htmlFor="projectName"
                                className="block text-sm text-left font-medium text-gray-700"
                              >
                                Assigned to
                              </label>
                              <div className="flex flex-wrap gap-2 mb-2">
                                {(assignee ?? []).map((roles, index) => (
                                  <span
                                    key={index}
                                    className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm font-medium flex items-center"
                                  >
                                    {roles.name}
                                    {
                                      <X
                                        className="w-4 h-4 ml-2 cursor-pointer"
                                        onClick={() => {
                                          deleteAssignee(roles);
                                        }}
                                      />
                                    }
                                  </span>
                                ))}
                              </div>
                              {(userProjectRoles ?? [])
                                .filter(
                                  (ele) =>
                                    !assignee.find(
                                      (ele2) =>
                                        ele2.id === ele.userId ||
                                        ele.userId === manager
                                    )
                                )
                                .map((user: UserProjectRoles) => ({
                                  name: user.name,
                                  type: user.email,
                                  id: user.userId,
                                })).length !== 0 && (
                                <div className="h-10">
                                  <SearchAutoCompleteDropDown
                                    placeholder="Add assignee"
                                    onChange={(e) => {
                                      setNewAssignee(e ?? "");
                                    }}
                                    data={
                                      (userProjectRoles ?? [])
                                        .filter(
                                          (ele) =>
                                            !assignee.find(
                                              (ele2) =>
                                                ele2.id === ele.userId ||
                                                ele.userId === manager
                                            )
                                        )
                                        .map((user: UserProjectRoles) => ({
                                          name: user.name,
                                          type: user.email,
                                          id: user.userId,
                                        })) as SwitchDropDownModel[]
                                    }
                                    getSelectedValue={createAssignee}
                                    selectedOption={
                                      {
                                        name: "",
                                        id: "",
                                        type: "",
                                      } as SwitchDropDownModel
                                    }
                                    field={"name" as keyof SwitchDropDownModel}
                                  />
                                </div>
                              )}
                            </div>
                          )}
                          {projectsData && formik.values.projectId && (
                            <div className="flex justify-between mt-2">
                              <div className="relative flex items-start">
                                <div className="flex h-6 items-center">
                                  <input
                                    id="notification"
                                    aria-describedby="notification"
                                    name="notification"
                                    type="checkbox"
                                    checked={sendNotification}
                                    onClick={() => {
                                      setSendNotification(!sendNotification);
                                    }}
                                  />
                                </div>
                                <div className="ml-3 text-sm leading-6">
                                  <label
                                    htmlFor="comments"
                                    className="font-medium text-gray-900"
                                  >
                                    Send Notification
                                  </label>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="mb-4">
                            <label
                              htmlFor="tags"
                              className="block text-sm text-left font-medium text-gray-700"
                            >
                              Tags
                            </label>
                            <Input
                              type="text"
                              name="tags"
                              id="tags"
                              placeholder="Add Comma separated Tags eg: Tag1, Tag2"
                              value={formik.values.tags}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SideBarAddTaskPanel;
