import { useEffect, useState } from "react";
import {
  useGetUserProfile,
  useUpdateUserProfile,
} from "../../queries/userRole";
import Select from "react-select";
import { toast } from "react-toastify";
import { queryClient } from "../../queries/client";
import { handleClickForTracking } from "../../analytics";
import { AnalyticsConstant } from "../../AnalyticsContant";
import { useGetProjectSubTemplates, useGetProjectTypes } from "../../queries/admin";
import TemplatesAgTable from "./TemplatesAgTable";

export default function Example() {
  const [firstName, setFirstName] = useState("");
  const [filteredTemplates, setFilteredTemplates] = useState<any>();
  const [mail, setMail] = useState("");
  const { data } = useGetUserProfile();
  useEffect(() => {
    if (data) {
      setFirstName(data?.name ?? "");
      setMail(data?.emailId ?? "");
    }
  }, [data]);

  const { mutate: updateProfile } = useUpdateUserProfile();
  const { data: availableProjectTypes } = useGetProjectTypes();
  const { data: allSubTemplates } = useGetProjectSubTemplates();

  const [selectedProject, setSelectedProject] = useState<{
    label: string;
    value: string;
  }>();

  useEffect(() => {
    if (availableProjectTypes)
      setSelectedProject({
        label: availableProjectTypes?.[0]?.type,
        value: availableProjectTypes?.[0]?.type,
      });
  }, [availableProjectTypes]);

  const projectOptions: { label: string; value: string }[] = [];
  availableProjectTypes?.forEach((project: any) => {
    projectOptions.push({
      label: project.type,
      value: project.type,
    });
  });

  useEffect(()=>{
    if(selectedProject && allSubTemplates){
      const currentTemplates = allSubTemplates.filter((template: any) => template.projectType === selectedProject.label)
      setFilteredTemplates(currentTemplates)
    }
  },[selectedProject, allSubTemplates])
  return (
    <>
      <div className="bg-white rounded-lg shadow-box border border-borderColor p-9">
        {data && (
          <div className="pb-6">
            <h2 className="text-base font-bold leading-7 text-gray-900">
              Profile Information
            </h2>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  First name
                </label>
                <div className="mt-2">
                  <input
                    id="first-name"
                    name="first-name"
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                    type="text"
                    autoComplete="given-name"
                    className="block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    value={mail}
                    disabled
                    onChange={(e) => {
                      setMail(e.target.value);
                    }}
                    name="email"
                    type="email"
                    autoComplete="email"
                    className="block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="mt-6 flex items-center justify-end gap-x-6">
          {firstName !== data?.name && (
            <button
              onClick={() => {
                handleClickForTracking(
                  AnalyticsConstant.settings_save_changes_button
                );
                if (firstName) {
                  updateProfile(
                    {
                      userId: data.userId ?? "",
                      name: firstName,
                    },
                    {
                      onSuccess() {
                        toast("User profile updated", {
                          type: "success",
                          autoClose: 2000,
                        });
                        queryClient.invalidateQueries("getUserProfile");
                      },
                      onError(err) {
                        toast("User profile updated", {
                          type: "success",
                          autoClose: 2000,
                        });
                      },
                    }
                  );
                }
              }}
              className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
              Save Changes
            </button>
          )}
        </div>
      </div>

      <div className=" my-6 bg-white rounded-lg shadow-box border border-borderColor p-9">
        <div className="pb-6">
          <h2 className="text-base font-bold leading-7 text-gray-900">
            Sub Templates
          </h2>
          <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Select Project Type
              </label>
              <div className="mt-2">
                <Select
                  className="text-sm"
                  options={projectOptions}
                  name="company"
                  classNamePrefix="select2-selection"
                  onChange={(e: any) => {
                    // handleUserNavigation(e.value);
                    setSelectedProject(e);
                  }}
                  value={selectedProject}
                />
              </div>
            </div>
          </div>
        </div>
        {selectedProject && <TemplatesAgTable templateData={filteredTemplates} selectedProject={selectedProject} projectOptions={projectOptions}/>}
      </div>
    </>
  );
}
