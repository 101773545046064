import { memo, useMemo, useRef, useState } from "react";
import { Box, HStack } from "../../shared/utils";
import GenericTableHeader from "../aggrid/TableHeader";
import SearchInput from "../myTask/SearchInput";
import { AgGridReact } from "ag-grid-react";
import { ButtonSize, IconCTAButton, PrimaryCTAButton } from "../../shared/CTAButtonComponents";
import { CellClickedEvent, ColDef } from "ag-grid-community";
import { tableCellStyle } from "../aggrid/AgComponent";
import { CTADropdown } from "../../shared/Dropdown";
import { Download, FilePlus2, Trash } from "lucide-react";
import { agConfigDashboardSideBar } from "../aggrid/AgConfig";
import { TaskExtractModel } from "../myTask/useExtractMyTask";
import { useAuthStore } from "../../store/useAuthStore";
import CreateTemplateModal from "../../modal/AddTemplateModal";
import { ActionMenuRender, ProjectTypeRender, TemplateNameRender } from "./TemplatesAgTableComponents";

export default function TemplatesAgTable(props: any) {
  const gridApi = useRef<any>(null);
  const templateData = props.templateData || [];
  const [isColumnOpen, setIsColumnOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isCreateTemplate, setIsCreateTemplate] = useState(false);
  const isAdmin = useAuthStore.getState().role === "ADMIN";
    const [modalOpen, setModelOpen] = useState<{
      state: boolean;
      type: "Delete" | "Download";
      data: any;
    }>({
      type: "Delete",
      state: false,
      data: undefined,
    });
  const displayedRowCount = gridApi.current?.api.getDisplayedRowCount();  

  const componentsRegistery = useMemo(
    () => ({
      templateName: memo(TemplateNameRender),
      projectType: memo(ProjectTypeRender)
    }),
    []
  );
  const gridOptions = {
    suppressRowVirtualisation: true,
    paginationAutoPageSize: false,
    suppressScrollOnNewData: true,
  };
  const openToolPanel = (key: any) => {
    if (key === "columns") {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsColumnOpen((state) => !state);
        setIsFilterOpen(false);
      }
    } else if (key === "filters") {
      if (gridApi) {
        if (!isFilterOpen) {
          gridApi?.current?.api?.openToolPanel(key);
        } else gridApi?.current?.api?.closeToolPanel();
        setIsFilterOpen((state) => !state);
        setIsColumnOpen(false);
      }
    }
  };
  function handleModifyAction(action: string, data: any, index: number) {
   if (action === "Delete") {
    setModelOpen({
        state: true,
        type: "Delete",
        data,
      });
    } else if (action === "Download") {
      setModelOpen({
        state: true,
        type: "Download",
        data: data.id,
      });
    }
  }

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: "Template Name",
        field: "templateName",
        filter: "agSetColumnFilter",
        cellRenderer: "templateName",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        initialWidth: 800,
        autoHeight: true,
        wrapText: true,
        suppressSizeToFit: true,
      },
      {
        headerName: "Type",
        field: "projectType",
        filter: "agSetColumnFilter",
        cellRenderer: "projectType",
        cellStyle: tableCellStyle,
        // initialWidth: 350,
        sortable: true,
        hide: false,
        menuTabs: ["filterMenuTab"],
      },
      {
        headerName: "",
        field: "actions",
        pinned: "right",
        hide: false,
        width: 120,
        maxWidth: 120,
        filter: false,
        menuTabs: [],
        colId: "action-column",
        suppressNavigable: true,
        suppressColumnsToolPanel: true,
        cellStyle: { "padding-top": "12px", "line-height": "18px" },
        resizable: false,
        sortable: false,
        cellRendererParams: ({ value }: { value: any }) => value?.props,
        cellRenderer: ActionMenuRender
        // cellRenderer: CTADropdown,
      },
    ],
    [templateData]
  );

  const rowData = useMemo(
    () =>
      templateData.map((template: any, index: number) => {
        const quickActions = (
          <CTADropdown
            actions={[
              {
                name: "Delete",
                icon: <Trash className="w-3 h-3" />,
                disabled: false,
                data: template
              },
              {
                name: "Download",
                icon: <Download className="w-3 h-3" />,
                disabled: false,
                data: template
              },
            ]}
            onAction={(action) => {
              if (action.name === "Delete") {
                setModelOpen({
                    state: true,
                    type: "Delete",
                    data:action.data,
                  });
                } else if (action.name === "Download") {
                  setModelOpen({
                    state: true,
                    type: "Download",
                    data: action.data,
                  });
                }
              // handleModifyAction(action.name, template, index);
            }}
          />
        );
        return {
          id: template.id,
          templateName: template.name,
          projectType: template.projectType,
          fileUrl: template.fileUrl,
          isAdmin: isAdmin,
          actions: quickActions,
          types: props.projectOptions?.map((project:any) => project.label)
        };
      }),
    [templateData]
  );

  function setTableHeight() {
    if (displayedRowCount === 1) {
      return (displayedRowCount + 2.78) * 60;
    } else if (displayedRowCount === 2) {
      return (displayedRowCount + 2.94) * 60;
    } else if (displayedRowCount === 3) {
      return (displayedRowCount + 3) * 60;
    } else if (displayedRowCount === 4) {
      return (displayedRowCount + 2.75) * 60;
    } else if (displayedRowCount === 5) {
      return (displayedRowCount + 2.7) * 60;
    } else if (displayedRowCount === 6) {
      return (displayedRowCount + 2.55) * 60;
    } else if (displayedRowCount === 7) {
      return (displayedRowCount + 2.35) * 60;
    } else if (displayedRowCount === 8) {
      return (displayedRowCount + 2.28) * 60;
    } else if (displayedRowCount === 9) {
      return (displayedRowCount + 2.22) * 60;
    } else {
      return 10 * 60;
    }
  }  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 100,
      filter: true,
      resizable: true,
      flex:1,

    }),
    []
  );
    const [isUpdateProjectDetails, setUpdateProjectDetails] = useState<{
      state: boolean;
      task?: TaskExtractModel;
    }>({
      state: false,
      task: undefined,
    });
    

    const handleCellClick = (cellParams: CellClickedEvent<any, any>) => {
      if (cellParams.column.getColId() !== "action-column") {
        const template = cellParams.data;
  
        setUpdateProjectDetails({
          task: template,
          state: true,
        });
      }
    };
  return (
    <>
      {/* <div className="shadow-box border border-borderColor bg-white rounded-md px-2 py-6"> */}
        {/* <HStack className="flex-col lg:flex-row justify-start md:justify-between py-2 lg:items-center items-start">
          <div>
            <GenericTableHeader
              heading={"Templates"}
              //   subHeading={`${filteredDirectorData?.length} Directors`}
              subHeading=""
            />
          </div> */}
          <HStack className="flex-col  items-start justify-start p-2 bg-white border rounded-md rounded-tr-none lg:flex-row lg:justify-between lg:items-center border-borderColor shadow-box w-full">
          <HStack className="gap-4 items-center">
          <SearchInput
              placeholder={`Search`}
              onChange={(e: any) => {
                gridApi.current.api.setQuickFilter(e.target.value);
              }}
            />
            {isAdmin && isCreateTemplate && (
        <CreateTemplateModal
        template={''}
        isOpen={isCreateTemplate}
        onClose={() => {
          setIsCreateTemplate(false);
        }}
        existingProjectType={props.selectedProject}
        onCreateTemplate={() => {
          setIsCreateTemplate(false)
        }}
      />
      )}
          </HStack>
            <HStack className="gap-4 mt-2 lg:mt-0">
            <PrimaryCTAButton
                  onClick={() => {
                    // handleClickForTracking(
                    //   project
                    //     ? AnalyticsConstant.project_table_create_task_button
                    //     : AnalyticsConstant.project_table_create_task_button
                    // );

                    setIsCreateTemplate(true);
                  }}
                  buttonSize={ButtonSize.MEDIUM}
                >
                  <span className="hidden lg:block">Add Template</span>
                  <FilePlus2 className="w-4 h-4 lg:ml-2"/>
                </PrimaryCTAButton>
                <IconCTAButton
                    value={"Columns"}
                    onClick={() => {
                      // handleClickForTracking(
                      //   project
                      //     ? AnalyticsConstant.project_table_column_tab
                      //     : AnalyticsConstant.table_column_tab
                      // );
                      openToolPanel("columns");
                    }}
                    selected={isColumnOpen}
                    iconName={"basil:settings-adjust-solid"}
                    iconClassName="block mx-2 sm:mx-0"
                    textClassName="sm:block hidden"
                    className={`sm:px-4 px-1 font-medium items-center flex flex-row ${
                      isColumnOpen ? "text-orange-501" : "text-gray-400"
                    }`}
                  />
                <IconCTAButton
                    value={"Filters"}
                    onClick={() => {
                      // handleClickForTracking(
                      //   project
                      //     ? AnalyticsConstant.project_table_column_tab
                      //     : AnalyticsConstant.table_column_tab
                      // );
                      openToolPanel("filters");
                    }}
                    selected={isFilterOpen}
                    iconName={"mingcute:filter-line"}
                    iconClassName="block mx-2 sm:mx-0"
                    textClassName="sm:block hidden"
                    className={`sm:px-4 px-1 font-medium items-center flex flex-row ${
                      isFilterOpen ? "text-orange-501" : "text-gray-400"
                    }`}
                  />
                        {/* {isUpdateProjectDetails.state && (
        <SideBarPanel
          tags={[]}
          realTasks={isUpdateProjectDetails.task}
          open={isUpdateProjectDetails.state ?? false}
          onClose={() => {
            setUpdateProjectDetails({ state: false, task: undefined });
          }}
          isAdmin={true}
        />
      )} */}
            </HStack>
          </HStack>
        {/* </HStack> */}

        <HStack className="justify-between relative w-full">
          <Box
            style={{
              height: setTableHeight(),
            }}
            className="w-full max-h-full min-h-96 overflow-x-auto ag-theme-material h-full "
          >
            <AgGridReact
              sideBar={agConfigDashboardSideBar}
              onGridReady={(params) => {
                gridApi.current = params;
              }}
              components={componentsRegistery}
              alwaysShowHorizontalScroll
              alwaysMultiSort
              animateRows={true}
              defaultColDef={defaultColDef}
              rowData={rowData}
              onCellClicked={handleCellClick}
              columnDefs={columnDefs}
              pagination={false}
              suppressRowTransform={true}
              suppressCopyRowsToClipboard={true}
              suppressCopySingleCellRanges={true}
              suppressCellFocus={true}
              suppressMenuHide={false}
              // onColumnResized={uploadColumn}
              tooltipShowDelay={1000}
              tooltipInteraction={true}
              rowClass={"border-t border-dashed"}
              gridOptions={gridOptions}
              overlayNoRowsTemplate={
                ' <div style="text-align: center; padding: 40px; "> <div style="display: inline-block;"><div style="font-size: 18px; color: #555; margin-bottom: 8px;">Table is empty</div><div style="font-size: 14px; color: #777;">No data available at the moment</div></div></td>'
              }
              getRowStyle={(params) => {
                if (params.data.isRead) {
                  return { background: "#F2F6FB" };
                } else {
                  return { background: "#ffffff" };
                }
              }}
            ></AgGridReact>

          </Box>
        </HStack>
      {/* </div> */}
    </>
  );
}
