import {
  Download,
  FileIcon,
  FileText,
  SquareArrowOutUpRight,
  Trash2,
} from "lucide-react";
import { HStack } from "../../shared/utils";
import { useDeleteSubTemplate } from "../../queries/admin";
import { toast } from "react-toastify";
import { queryClient } from "../../queries/client";
import { limitString } from "../../utils/string";
import { useState } from "react";
import { Icon } from "@iconify/react";

export function ActionMenuRender(data: any) {
  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
  const [isDownloadLoading, setIsDownloadLoading] = useState<boolean>(false);
  const { mutate: deleteTemplate } = useDeleteSubTemplate();

  const handleDownload = () => {
    const url = data.data.fileUrl;
    const link = document.createElement("a");
    link.href = url;
    link.click();
    setIsDownloadLoading(false);
  };

  function handleDelete(templateId: string) {
    deleteTemplate(
      { templateID: templateId },
      {
        onSuccess: (data) => {
          toast("template Deleted Successfully", {
            type: "success",
            autoClose: 2000,
          });
          queryClient.invalidateQueries("subTemplates");
          queryClient.refetchQueries("subTemplates");
          queryClient.invalidateQueries("projectTypes");
          setIsDeleteLoading(false);
        },
        onError: (error: any) => {
          setIsDeleteLoading(false);
          toast(error, {
            type: "error",
            autoClose: 2000,
          });
        },
      }
    );
  }

  return (
    <HStack className="items-center gap-4">
      <button
        onClick={() => {
          setIsDownloadLoading(true);
          handleDownload();
        }}
        className="text-gray-400 hover:text-blue-600 p-1 rounded-full hover:bg-blue-100 transition-all duration-200"
      >
        {!isDownloadLoading && <Download className="w-5 h-5" />}
        {isDownloadLoading && (
          <Icon className="animate-spin" icon="lucide:loader-2" width={18} />
        )}
      </button>
      {data.data.isAdmin && (
        <button
          onClick={(e) => {
            e.stopPropagation();
            handleDelete(data.data.id);
            setIsDeleteLoading(true);
          }}
          className="text-gray-400 hover:text-red-600 p-1 rounded-full hover:bg-red-100 transition-all duration-200"
        >
          {!isDeleteLoading && <Trash2 className="w-5 h-5" />}
          {isDeleteLoading && (
            <Icon className="animate-spin" icon="lucide:loader-2" width={18} />
          )}
        </button>
      )}
    </HStack>
  );
}

const colorPalette = [
  "bg-[#241DBA]/[0.1] text-[#241DBA]/[0.8]",
  "bg-[#2DA900]/[0.1] text-[#2DA900]/[0.8]",
  "bg-[#00A3C7]/[0.1] text-[#00A3C7]/[0.8]",
  "bg-[#E79212]/[0.1] text-[#E79212]/[0.8]",
  "bg-[#F86161]/[0.1] text-[#F86161]/[0.8]",
  "bg-[#6904DC]/[0.3] text-[#6904DC]/[0.8]",
  "bg-[#BAA8ED]/[0.1] text-[#D5CBF4]/[0.8]",
  "bg-[#FF9F40]/[0.1] text-[#FF9F40]/[0.8]",
  "bg-[#36A2EB]/[0.1] text-[#36A2EB]/[0.8]",
  "bg-[#FF6384]/[0.1] text-[#FF6384]/[0.8]",
];

const tagsColorMap = new Map<string, string>();

const getColorByIndex = (index: number) =>
  colorPalette[index % colorPalette.length];

export function ProjectTypeRender(data: any) {
  const index =
    data.data.types?.findIndex(
      (type: string) => type === data.data.projectType
    ) || 0;
  return (
    <>
      <div
        className={`${getColorByIndex(
          index || 0
        )} inline-flex items-center gap-x-0.5 rounded-md px-2 py-1 text-xs font-medium mb-3 mt-2`}
      >
        <p>{data.data.projectType}</p>
      </div>
    </>
  );
}

export function TemplateNameRender(data: any) {
  return (
    <>
      <HStack>
        <div className={`p-3 px-3 rounded-full bg-green-100 mb-1`}>
          <FileText className={`w-6 h-6 text-green-500`} />
        </div>
        <span className="text-sm font-bold text-gray-700 block m-3">
          {limitString(data.data.templateName, 50)}
        </span>
      </HStack>
    </>
  );
}
