/* eslint-disable no-undef */

export type TooltipProps = {
  text: string;
  children: JSX.Element;
  _className?: string;
  _position?: string;
  position?: string;
  disabled?: boolean;
};
function Tooltip(props: TooltipProps) {
  const { text, children, _className, disabled = false } = props;
  return (
    <div className="relative group ">
      {children}
      <div
        style={{ zIndex: 100, position: "absolute" }}
        className={`absolute bottom-0 whitespace-normal z-50 overflow-x-visible text-wrap
          ${props.position}
         flex-col items-center hidden mb-6  ${
           disabled ? "" : "group-hover:flex"
         }`}
      >
        <div
          className={`relative p-2 text-sm text-black shadow-lg bg-[#f6f9fb] rounded-md  ${_className}`}
        >
          {text}
        </div>
      </div>
    </div>
  );
}

export default Tooltip;
