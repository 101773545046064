import React, { useState, useMemo, useRef, useEffect } from "react";
import { TaskExtractModel } from "../myTask/useExtractMyTask";

interface ProjectFilterProps {
  data: TaskExtractModel[];
  onSelect?: any;
  label?: string;
}

const ProjectFilter: React.FC<ProjectFilterProps> = ({
  data,
  onSelect = "",
  label = "Project",
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Extract unique projects from data
  const allProjects = useMemo(() => {
    const projectSet = new Set<string>();
    data.forEach((item) => {
      if (item?.projectName) {
        projectSet.add(item.projectName);
      }
    });
    return Array.from(projectSet);
  }, [data]);

  // State for selected projects
  const [selectedProjects, setSelectedProjects] = useState<Set<string>>(
    new Set()
  );

  // Handle checkbox changes
  const handleProjectChange = (project: string): void => {
    const newSelected = new Set(selectedProjects);
    if (newSelected.has(project)) {
      newSelected.delete(project);
    } else {
      newSelected.add(project);
    }
    setSelectedProjects(newSelected);
    onSelect(newSelected);
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="bg-white px-2 py-1 h-10 text-sm rounded-lg shadow flex items-center justify-between w-"
        type="button"
      >
        <span className="text-gray-700 sm:block hidden whitespace-nowrap">
          {selectedProjects.size
            ? `${selectedProjects.size} ${label} selected`
            : `Filter by ${label}s`}
        </span>
        <span className="text-gray-700 sm:hidden block whitespace-nowrap">
          {selectedProjects.size
            ? `${selectedProjects.size} projects`
            : "Projects"}
        </span>
        <svg
          className={`w-4 h-4 transition-transform ${
            isOpen ? "rotate-180" : ""
          }`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>

      {isOpen && (
        <div className="absolute top-full mt-2 w-48 bg-white rounded-lg shadow-lg py-2 z-10">
          {allProjects.map((project) => (
            <label
              key={project}
              className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
            >
              <input
                type="checkbox"
                checked={selectedProjects.has(project)}
                onChange={() => handleProjectChange(project)}
                className="w-4 h-4 mr-2 flex-shrink-0"
              />
              <span className="text-gray-700 text-sm">{project}</span>
            </label>
          ))}
          {allProjects.length === 0 && (
            <label
              key={"No Projects"}
              className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
            >
              <span className="text-gray-700 text-sm">
                {"No Projects Available"}
              </span>
            </label>
          )}
        </div>
      )}
    </div>
  );
};

export default ProjectFilter;
